<template>
  <div class="logout">
    <vue-headful title="Logged Out | ShrinkLink"/>
    <div class="page-background"></div>
      <b-container>
        <b-row v-if="!showDeniedError">
          <b-col sm="12">
            <h2>You have successfully logged out.</h2>
          </b-col>
          <b-col sm="12">
            <a class="btn" href="/login">Log Back In</a>
          </b-col>
        </b-row>
        <b-row v-if="showDeniedError">
          <b-col sm="12">
            <h2>You do not have access to this application.</h2>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'logout',
  data () {
    return {
      showDeniedError: false
    }
  },
  mounted () {
    this.checkParams()
  },
  methods: {
    checkParams () {
      let params = this.$route.query.error;

      if (params) {
        this.showDeniedError = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logout {
  margin-bottom: 25px;
  text-align: center;
}
.logout > .container {
  margin-top: 35px;
}
h2 {
  font-size: 32px;
  color: #fff;
  padding-bottom: 20px;
}
.btn {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .logout > .container {
      margin-top: 65px;
  }
}
</style>
