<template>
  <div class="login">
    <vue-headful title="Log In | ShrinkLink"></vue-headful>
    <div class="container text-center">
      <div class="card">
        <div class="card-body">
          <p>On the Goodyear network? Click below to use your GoPass credentials.</p>
          <a class="btn" href="/server/login">Log In</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import router from '../router'
import axios from 'axios'
export default {
  name: 'Login',
  /* mounted () {
    this.login()
  }, */
  methods: {
    login: (e) => {
      e.preventDefault()

      axios.get('/server/login')
        .then((response) => {
          console.log('Logged in')
        })
        .catch((errors) => {
          console.log('Cannot log in')
          console.log('Errors: ' + errors)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  color: #333;
}
.container {
    margin-top: 35px;
}
.card {
  max-width: 20rem;
  margin: 0 auto;
}
.card-body .btn {
  margin-top: 55px;
}

@media (min-width: 768px) {
  .login > .container {
      margin-top: 65px;
  }
}
</style>
