<template>
  <div class="update">
    <vue-headful title="Update URL | ShrinkLink"></vue-headful>
    <div class="container text-center">
      <h1>Update Existing Link</h1>
      <p>Find your previously shortened aka.gy URL details below first to edit.</p>
    </div>

    <div class="callout">
      <div class="container">
        <form id="updateLink" class="form-row justify-content-center" @submit="getUrlDetails" v-if="!showDetails && !showSuccess">
          <div class="col-5">
              <input class="form-control" v-model="form.recordedShortUrl" type="text" placeholder="Existing short URL (http://aka.gy/example)" required/>
          </div>
          <div class="col-auto">
            <button type="submit" class="btn" v-if="!loading">
              Search
              <b-icon icon="search" class="search"></b-icon>
            </button>
            <b-spinner label="Loading..." v-if="loading === 1"></b-spinner>
          </div>
        </form>

        <div class="url-details form-row justify-content-center" v-if="showDetails">
          <div class="col-12 text-center">
            <h2>{{form.fullShortUrl}}</h2>
          </div>
          <div class="col-10 text-center">
            <p>
              <b-badge pill variant="info">Current</b-badge>
              {{form.origLongUrl}}
              </p>
          </div>
          <div class="col-8">
              <input class="form-control" v-model="form.newLongUrl" type="text" placeholder="New long URL here" required/>
          </div>
          <div class="col-auto">
            <a class="btn" @click="updateShortLink" v-if="!editSuccess && !loading">Update </a>
            <b-spinner label="Loading..." v-if="loading === 2"></b-spinner>
            <div class="copy-msg" v-if="editSuccess">
              <b-icon icon="check-circle" animation="throb" font-scale="4"></b-icon>
            </div>
          </div>
        </div>

        <div class="gy-success url-details form-row justify-content-center" v-if="showSuccess">
          <div class="col-12 text-center">
            <h2><a v-bind:href="form.fullShortUrl" target="_blank">{{form.fullShortUrl}}</a></h2>
            <a class="btn" @click="copyLink" v-if="!copySuccess">Copy</a>
            <div class="copy-msg" v-if="copySuccess">
              <b-icon icon="check-circle" animation="throb" font-scale="4"></b-icon>
            </div>
          </div>
          <div class="col-10 text-center">
            <br/>
            <p>
              <b-badge pill variant="success">New</b-badge>
              {{form.newLongUrl}}
              </p>
          </div>
        </div>

        <!-- <div class="gy-success" v-if="showSuccess">
          <h2><a v-bind:href="form.fullShortUrl" target="_blank">{{form.fullShortUrl}}</a></h2>
          <a class="btn" @click="copyLink" v-if="!copySuccess">Copy</a>
          <div class="copy-msg" v-if="copySuccess">
            <b-icon icon="check-circle" animation="throb" font-scale="4"></b-icon>
          </div>
        </div> -->

        <div class="gy-fail" v-if="showError">
          <p v-if="formatError">Please check the format of the URL and try again. (http://aka.gy/example)</p>
          <p v-else-if="detailError">We couldn't find that short URL. Check that it is correct and try again.</p>
          <p v-else>An error has occurred. Please try again later. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
window.dataLayer = window.dataLayer || []
export default {
  name: 'Update',
  data () {
    return {
      loading: false,
      showSuccess: false,
      showError: false,
      showDetails: false,
      detailSuccess: false,
      detailError: false,
      formatError: false,
      editSuccess: false,
      copySuccess: false,
      form: {
        urlData: ''
      }
    }
  },
  methods: {
    animateSuccess: function (successType) {
      let self = this

      if (successType === 'copy') {
        // successType is copy
        self.copySuccess = true
        setTimeout(function () {
          self.copySuccess = false
        }, 4000)
      } else {
        // successType is edit
        self.editSuccess = true
        setTimeout(function () {
          self.editSuccess = false
        }, 4000)
      }
    },
    copyLink: function () {
      let ref = this
      this.$copyText(this.form.fullShortUrl).then(function (e) {
        ref.animateSuccess('copy')
        console.log(e)
      }, function (e) {
        // Can not copy
        console.log(e)
      })
    },
    getUrlDetails (evt) {
      evt.preventDefault()
      this.loading = 1
      this.showError = false

      // convert url to lowercase
      this.form.recordedShortUrl = this.form.recordedShortUrl.toLowerCase()

      // check for http
      // eslint-disable-next-line eqeqeq
      if (!this.form.recordedShortUrl.indexOf('http://') == 0) {
        this.loading = false
        this.showError = true
        return (this.formatError = true)
      }

      // convert to url
      const url = new URL(this.form.recordedShortUrl)

      // urlkey
      this.form.urlKey = url.pathname.slice(1)

      axios.post('/server/getUrlDetails', {
        urlKey: this.form.urlKey
      })
        .then(response => {
          this.loading = false

          this.urlData = response.data

          // eslint-disable-next-line eqeqeq
          if (!response.data.error) {
            // this.urlData = response.data

            this.form.origLongUrl = this.urlData.longUrl
            this.form.fullShortUrl = this.urlData.fullShortUrl
            this.form.pKey = this.urlData.pKey
            this.showDetails = true
          } else {
            console.log('urlData.error = ' + this.urlData.error)
            this.showError = true
            // eslint-disable-next-line eqeqeq
            if (this.urlData.error === 1) {
              this.detailError = true
            }
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    updateShortLink (evt) {
      evt.preventDefault()
      this.loading = 2
      this.showError = false

      axios.post('/server/updateShortLink', {
        pKey: this.form.pKey,
        urlKey: this.form.urlKey, // short url key only
        newLongUrl: this.form.newLongUrl // new long url
      })
        .then(response => {
          this.loading = false
          // console.log(response.data)
          if (response.data.success) {
            this.showSuccess = true
            this.showDetails = false
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Update Existing Link' })
          } else {
            this.showError = true
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.update > .container {
    margin-top: 35px;
    margin-bottom: 35px;
}
.callout {
  background-color: #113254;
  min-height: 150px;
  padding: 35px 0px;
}
.url-begin {
  font-family: "Barlow-SemiBold", Arial, sans-serif;
  color: #ff9f35;
  font-size: 2.0rem;
  line-height: 42px;
}
.form-control {
  height: 42px;
}
.search, .search.b-icon {
  margin-left: 2px;
  font-size: 100%;
}
.gy-success, .gy-fail, .url-details {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 10px 0px;
}
.gy-success h2 {
  display: inline;
  margin-right: 5px;
}
.gy-success h2, .gy-success h2>a, .url-details h2, .url-details h2>a {
  color: #e87900;
}
.gy-success p, .gy-fail p{
  margin-bottom: 0px;
}
.url-details p {
  color: #274ba7;
  font-size: 88%;
  word-break: break-all;
  font-weight: bold;
  margin-bottom: 25px;
}
.gy-success .btn, .url-details .btn {
  float: right;
  background-color: #fd0;
  color: #333;
}
.spinner-border {
  color: #ff9f35;
}
.copy-msg {
  float: right;
  color: #5cb85c;
  margin-top: -12px;
}
.gy-fail {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

@media (min-width: 768px) {
  .update > .container {
      margin-top: 65px;
  }
  form .btn {
    min-width: 150px;
  }
  .url-details .badge {
    font-size: 95%;
    margin-right: 5px;
  }
  .gy-success, .gy-fail, .url-details {
    padding: 15px;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
</style>
