<template>
  <div class="create">
    <vue-headful title="ShrinkLink"></vue-headful>
    <div class="container text-center">
      <!-- <img src="../assets/logo.png"> -->
      <h1>Goodyear ShrinkLink</h1>
      <p>Paste your long URL below and click the shrink button to get a shortened version.</p>
    </div>

    <div class="callout">
      <div class="container">
        <form id="createLink" class="form-row justify-content-center" @submit="createShortLink">
          <div class="col-7">
              <input class="form-control" v-model="form.longUrl" type="text" placeholder="Make your link shorter" required/>
          </div>
          <div class="col-auto">
            <button type="submit" class="btn" v-if="!loading">Shrink </button>
            <b-spinner label="Loading..." v-if="loading"></b-spinner>
          </div>
        </form>
        <div class="gy-success" v-if="showSuccess">
          <h2><a v-bind:href="urlData.ShortUrlHttp" target="_blank">{{urlData.ShortUrlHttp}}</a></h2>
          <a class="btn" @click="copyLink" v-if="!copySuccess">Copy</a>
          <div class="copy-msg" v-if="copySuccess">
            <b-icon icon="check-circle" animation="throb" font-scale="4"></b-icon>
          </div>
        </div>

        <div class="gy-fail" v-if="showError">
          <p>An error has occurred. Please try again later. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
window.dataLayer = window.dataLayer || []
export default {
  name: 'Create',
  data () {
    return {
      loading: false,
      showSuccess: false,
      showError: false,
      copySuccess: false,
      form: {
        urlData: ''
      }
    }
  },
  methods: {
    /* makeToast: function () {
      this.$bvToast.toast(' ', {
        title: 'Short link copied!',
        variant: 'warning',
        solid: true
      })
    }, */
    displayCopySuccess: function () {
      let self = this
      self.copySuccess = true
      setTimeout(function () {
        self.copySuccess = false
      }, 4000)
    },
    copyLink: function () {
      let ref = this
      this.$copyText(this.urlData.ShortUrlHttp).then(function (e) {
        ref.displayCopySuccess()
        console.log(e)
      }, function (e) {
        // Can not copy
        console.log(e)
      })
    },
    createShortLink (evt) {
      evt.preventDefault()
      this.loading = true

      axios.post('/server/createShortLink', {
        longUrl: this.form.longUrl
      })
        .then(response => {
          this.loading = false
          if (!response.data.error) {
            this.urlData = response.data
            this.urlData.ShortUrlHttp = 'http://' + this.urlData.ShortUrl
            this.showSuccess = true
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Create Short Link' })
          } else {
            this.showError = true
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.create > .container {
    margin-top: 35px;
    margin-bottom: 35px;
}
.callout {
  background-color: #237BAF;
  min-height: 150px;
  padding: 35px 0px;
}
.gy-success, .gy-fail {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 10px 0px;
}
.gy-success h2 {
  display: inline;
  margin-right: 5px;
}
.gy-success h2>a {
  color: #e87900;
}
.gy-success p, .gy-fail p{
  margin-bottom: 0px;
}
.gy-success .btn {
  float: right;
  background-color: #fd0;
  color: #333;
}
.spinner-border {
  color: #ff9f35;
}
.gy-success .copy-msg {
  float: right;
  color: #5cb85c;
  margin-top: -12px;
}
.gy-fail {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

@media (min-width: 768px) {
  .create > .container {
      margin-top: 65px;
  }
  form .btn {
    min-width: 150px;
  }
  .gy-success, .gy-fail {
    padding: 15px;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
</style>
